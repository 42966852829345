import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API_ROUTES from '../../apiRoutes';
import API from '../../axiosApi';
import { nanoid } from "nanoid";

export const getHlsList = createAsyncThunk('getHlsList', async (payload) => {
  const response = await API.get(
    `${API_ROUTES.hls}?limit=${payload.limit}&page=${payload.page}`
  );

  return response.data;
});

export const savePlatforms = createAsyncThunk('savePlatforms', async (platforms) => {
  const response = await API.post(`${API_ROUTES.hls}/savePlatforms`, platforms, {
    headers: {
      accept: 'application/json',
    },
  });

  return response.data;
});

export const changePlatform = createAction('changePlatform', (platform) => {
  return {
    payload: platform,
  }
});

export const getHlsDetail = createAsyncThunk('getHlsDetail', async (id) => {
  const response = await API.get(`${API_ROUTES.hls}/${id}`);
  return response.data;
});

export const getFillers = createAsyncThunk('getFilters', async (filters) => {
  const response = await API.get(API_ROUTES.fillers, {
    params: filters,
  });
  return response.data;
});

export const deleteHls = createAsyncThunk('deleteHls', async (id) => {
  const response = await API.delete(`${API_ROUTES.hls}/${id}`);
  return response.data;
});

export const deleteHlsList = createAsyncThunk('deleteHlsList', async (ids) => {
  const response = await API.delete(
    API_ROUTES.hls,
    {
      data: { ids },
    },
    {
      headers: {
        accept: 'application/json',
      },
    }
  );
  return response.data;
});

export const changeHlsPageSize = createAction('changeHlsPageSize', function prepare(
  pageSize
) {
  return {
    payload: pageSize,
  };
});

export const changeHlsPage = createAction('changeHlsPage', function prepare(page) {
  return {
    payload: page,
  };
});

export const changeCurrentHls = createAction(
  'changeCurrentHls',
  function prepare(newHls) {
    return {
      payload: newHls,
    };
  }
);

export const saveHls = createAsyncThunk('saveHls', async (hls) => {
  const response = await API.post(`${API_ROUTES.hls}/save`, hls, {
    headers: {
      accept: 'application/json',
    },
  });

  return response.data;
});

export const saveEPGIcon = createAsyncThunk('saveEPGIcon', async (data) => {
  const response = await API.post(`${API_ROUTES.hls}/saveepgicon`, data, {
    headers: {
      accept: 'application/json',
    },
  });

  return response.data;
});


export const uploadEPGIcon = createAsyncThunk('uploadEPGIcon', async (data) => {
  const response = await API.post(`${API_ROUTES.hls}/uploadepgicon`, data);

  return response.data;
});

export const resetCurrentHls = createAction('resetCurrentHls');

export const addDayToSchedule = createAction('addDayToSchedule');

export const deleteStream = createAction('deleteStream', function prepare(id) {
  return { payload: id };
});

export const clearStream = createAction('clearStream', function prepare(id) {
  return { payload: id };
});

export const cloneStream = createAction('cloneStream', function prepare(id) {
  return { payload: id };
});

export const selectStreamDay = createAction('selectStreamDay', function prepare(
  id
) {
  return { payload: id };
});

export const addStreamList = createAction('addStreamList', function prepare(
  streamList,
  dayId
) {
  return { payload: { timelineItems: streamList, dayId: dayId } };
});

export const changeCurrentHlsInfoByKey = createAction(
  'changeCurrentHlsInfoByKey',
  function prepare(key, value) {
    return { payload: { key, value } };
  }
);

export const changeDayInfoByKey = createAction(
  'changeDayInfo',
  function prepare(dayId, key, value) {
    return { payload: { dayId, key, value } };
  }
);

export const setTimeZones = createAction('setTimeZones', function prepare(
    items
) {
  return { payload: items };
});


export const selectTimeItem = createAction('selectTimeItem', function prepare(
  item
) {
  return { payload: item };
});

export const deleteTimelineItem = createAction(
  'deleteTimelineItem',
  function prepare(streamId, timelineItemId) {
    return { payload: { streamId, timelineItemId } };
  }
);

export const previewItemClose = createAction(
    'previewItemClose',
    function prepare(item) {
      return { payload: item };
    }
)
export const previewItemLink = createAction('previewItemLink', function prepare(item) {
  return {
    payload: item,
  };
});
export const previewItem = createAsyncThunk('previewItem', async (item) => {
  if (!item) {
    return { payload: item };
  } else {
    const response = await API.post(API_ROUTES.fileLink, item);
    return response.data;
  }
});
export const srtItem = createAction('srtItem', function prepare(item) {
  return {
    payload: item,
  };
});


export const previewStreamClose = createAction(
    'previewStreamClose',
    function prepare(item) {
      return { payload: item };
    }
)
export const previewStreamLink = createAction('previewStreamLink', function prepare(item) {
  return {
    payload: item,
  };
});
export const previewStream = createAsyncThunk('previewStream', async (item) => {
  if (!item) {
    return { payload: item };
  } else {
    const response = await API.post(API_ROUTES.fileLink, item);
    return response.data;
  }
});


export const setAdsStartTime = createAction('setAdsStartTime', function prepare(
  duration
) {
  return { payload: duration };
});

export const addStreamAds = createAction('addStreamAds', function prepare(
  streamId,
  timelineItemKey,
  ads
) {
  return { payload: { streamId, timelineItemKey, ads } };
});

export const changeStreamAds = createAction('changeStreamAds', function prepare(
  streamId,
  timelineItemKey,
  ads
) {
  return { payload: { streamId, timelineItemKey, ads } };
});

export const deleteStreamAd = createAction('deleteStreamAd', function prepare(
  streamId,
  timelineItemId,
  adKey
) {
  return { payload: { streamId, timelineItemId, adKey } };
});

export const changeItemOrder = createAction('changeItemOrder', function prepare(
  streamId,
  itemKey,
  orderType
) {
  return { payload: { streamId, itemKey, orderType } };
});

export const changeItemOrderDragAndDrop = createAction('changeItemOrderDragAndDrop', function prepare(
    streamId,
    newIndex,
    oldIndex
) {
  return { payload: { streamId, newIndex, oldIndex} };
});

export const saverename = createAsyncThunk('saverename', async (data) => {
  const response = await API.post(`${API_ROUTES.rename}`, data, {
    headers: {
      accept: 'application/json',
    },
  });

  return response.data;
});

export const updateLiveStreamitem = createAction('updateLiveStreamitem', function prepare(data) {
  return {payload: data};
});

export const addFiller = createAction('addFiller', function prepare(item) {
  let filler = {
    id: item._id,
    originalName: item.originalName,
    ads: [],
    key: nanoid(),
    fileName: item.fileName,
    storage: item.storage,
    bucket: item.bucket,
    duration: item.duration,
    description: item?.description || '',
    epnum: item?.epnum || ''
  };

  return {
    payload: filler,
  };
});

export const removeFiller = createAction('removeFiller', function prepare(item) {
  return {
    payload: item,
  };
});

export const setStartItem = createAction('setStartItem', function prepare({start, itemKey, streamId}) {
  // TODO: save to database

  return {
    payload: {
      itemKey,
      start,
      streamId,
    }
  }
});

export const addClipboardMetaData = createAsyncThunk('addClipboardMetaData', async (clipboardMetaData) => {
  const data = {
    key: clipboardMetaData.key,
    contentLabel: clipboardMetaData.contentLabel,
    contentTitle: clipboardMetaData.contentTitle,
    episodeNumber: clipboardMetaData.episodeNumber,
    id1: clipboardMetaData.id1,
    id2: clipboardMetaData.id2,
    contentDescription: clipboardMetaData.contentDescription,
  }

  const response = await API.post(`${API_ROUTES.hls}/${clipboardMetaData._id}/clipboard-metadata`, data, {
    headers: {
      accept: 'application/json',
    },
  });

  return response.data;
});

export const deleteClipboardMetaData = createAsyncThunk('deleteClipboardMetaData', async (data) => {
  const response = await API.delete(
      `${API_ROUTES.hls}/${data._id}/clipboard-metadata`,
      {
        data: {ids: data.ids},
      },
      {
        headers: {
          accept: 'application/json',
      },
      });

  return response.data;
});

export const changeSelectedItems = createAction('changeSelectedItems', function prepare(selectedRows) {
  return {
    payload: selectedRows,
  };
});

export const applyClipboardToMediaItems = createAsyncThunk('applyClipboardToMediaItems', async (data) => {
  const { streams, clipboard } = data;

  const streamIds = streams.map( ( stream ) => { return stream.key } );

  const response = await API.post(`${API_ROUTES.hls}/${data._id}/apply-clipboard-to-media-items`,
      {
        streamIds,
        clipboard
      },
      {
        headers: {
          accept: 'application/json',
        },
      });

  return response.data;
});