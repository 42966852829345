const ROUTE_PATH = {
  home: '/',
  login: '/login',
  liveStream: '/liveStream',
  rtmp: '/rtmp',
  analytics: '/analytics',
  report: '/report',
  users: '/users',
  userCreator: '/users/create',
  userEditor: '/users/edit/:id',
  mrss: '/mrss',
  mrssCreator: '/mrss/create',
  mrssEditor: '/mrss/edit/:id',
  hls: '/hls',
  hlsCreator: '/hls/create',
  hlsEditor: '/hls/edit/:id',
  schedulerCreator: '/scheduler/',
  schedulerEditor: '/scheduler/:id',
  srt: '/srt',
  srtCreator: '/srt/create',
  srtEditor: '/srt/edit/:id',
  storage: '/storages',
  fillers: '/fillers',
  storageCreator: '/storages/create',
  storageEditor: '/storages/edit/:id',
  notFound: '*',
  categories: '/categories',
  categoryCreator: '/categories/create',
  categoryEditor: '/categories/edit/:id',
  channels: '/channels',
  channelCreator: '/channels/create',
  channelEditor: '/channels/edit/:id',
  advertisementBlock: '/advertisement-blocks',
  advertisementBlockEditor: '/advertisement-blocks/edit/:id',
  advertisementBlockCreator: '/advertisement-blocks/create',
  bucket: '/bucket',
  bucketCreate: '/bucket/create',
  bucketEdit: '/bucket/edit/:id',
  uploadFile: '/upload',
  stormcloud: '/stormcloud',
  adStorm: '/storm/ad',
};

export default ROUTE_PATH;
